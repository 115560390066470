import { MembershipLevel } from '@/types/member'

const levelSeekerConfig = {
  levelIcon: '@/assets/images/membership/icon-seeker.svg',
  levelFlatIcon: '@/assets/images/membership/flat-icon-seeker.svg',
  levelFlatIconLightMode: '@/assets/images/membership/flat-icon-seeker-light-mode.svg',
  levelImage: '@/assets/images/membership/level-image-seeker.svg',
  funmilesImages: [
    '@/assets/images/membership/funmiles-seeker-1.svg',
    '@/assets/images/membership/funmiles-seeker-2.svg',
    '@/assets/images/membership/funmiles-seeker-3.svg',
  ],
  funCoinsImages: ['@/assets/images/membership/funCoins-seeker-1.svg', '@/assets/images/membership/funCoins-seeker-2.svg'],
  levelColorClass: 'text-yellow-50',
  levelBackgroundColorClass: 'bg-yellow-10',
  commenterAvatarImagePath: '/images/comment-avatar-seeker.png',
  avatarBackgroundColorClass: 'bg-yellow-40',
  avatarImagePath: '/images/avatar-seeker.png',
  avatarBorderClass: 'border-yellow-50',
  linearGradientClass: 'bg-gradient-to-b from-yellow-10',
  levelThresholds: [100, 260],
}
const levelProConfig = {
  levelIcon: '@/assets/images/membership/icon-pro.svg',
  levelFlatIcon: '@/assets/images/membership/flat-icon-pro.svg',
  levelFlatIconLightMode: '@/assets/images/membership/flat-icon-pro-light-mode.svg',
  levelImage: '@/assets/images/membership/level-image-pro.svg',
  funmilesImages: [
    '@/assets/images/membership/funmiles-pro-1.svg',
    '@/assets/images/membership/funmiles-pro-2.svg',
    '@/assets/images/membership/funmiles-pro-3.svg',
  ],
  funCoinsImages: ['@/assets/images/membership/funCoins-pro-1.svg', '@/assets/images/membership/funCoins-pro-2.svg'],
  levelColorClass: 'text-pink-50',
  levelBackgroundColorClass: 'bg-pink-10',
  commenterAvatarImagePath: '/images/comment-avatar-pro.png',
  avatarBackgroundColorClass: 'bg-pink-40',
  avatarImagePath: '/images/avatar-pro.png',
  avatarBorderClass: 'border-pink-50',
  linearGradientClass: 'bg-gradient-to-b from-pink-10',
  levelThresholds: [100, 160],
}
const levelEliteConfig = {
  levelIcon: '@/assets/images/membership/icon-elite.svg',
  levelFlatIcon: '@/assets/images/membership/flat-icon-elite.svg',
  levelFlatIconLightMode: '@/assets/images/membership/flat-icon-elite-light-mode.svg',
  levelImage: '@/assets/images/membership/level-image-elite.svg',
  funmilesImages: [
    '@/assets/images/membership/funmiles-elite-1.svg',
    '@/assets/images/membership/funmiles-elite-2.svg',
    '@/assets/images/membership/funmiles-elite-3.svg',
  ],
  funCoinsImages: ['@/assets/images/membership/funCoins-elite-1.svg', '@/assets/images/membership/funCoins-elite-2.svg'],
  levelColorClass: 'text-blue-50',
  levelBackgroundColorClass: 'bg-blue-10',
  commenterAvatarImagePath: '/images/comment-avatar-elite.png',
  avatarBackgroundColorClass: 'bg-blue-40',
  avatarImagePath: '/images/avatar-elite.png',
  avatarBorderClass: 'border-blue-50',
  linearGradientClass: 'bg-gradient-to-b from-blue-10',
  levelThresholds: [100, 260],
}

export const levelConfigMap = {
  [MembershipLevel.SEEKER]: levelSeekerConfig,
  [MembershipLevel.PRO]: levelProConfig,
  [MembershipLevel.ELITE]: levelEliteConfig,
}

export function useMembershipLevel(info: any) {
  const level = computed<MembershipLevel>(() => info.value?.member_level ?? info.value?.benefit?.level ?? MembershipLevel.SEEKER)
  const levelConfig = computed(() => levelConfigMap[level.value])
  const levelName = computed(() => info.value?.member_level_name || info.value?.benefit?.level_name)
  const lowerCaseLevelName = computed(() => MembershipLevel[level.value].toLocaleLowerCase())

  const levelIcon = computed(() => levelConfig.value.levelIcon)
  const levelFlatIcon = computed(() => levelConfig.value.levelFlatIcon)
  const levelFlatIconLightMode = computed(() => levelConfig.value.levelFlatIconLightMode)
  const levelImage = computed(() => levelConfig.value.levelImage)
  const funmilesImages = computed(() => levelConfig.value.funmilesImages)
  const funCoinsImages = computed(() => levelConfig.value.funCoinsImages)
  const levelColorClass = computed(() => levelConfig.value.levelColorClass)
  const levelBackgroundColorClass = computed(() => levelConfig.value.levelBackgroundColorClass)
  const commenterAvatarImagePath = computed(() => levelConfig.value.commenterAvatarImagePath)
  const avatarBackgroundColorClass = computed(() => levelConfig.value.avatarBackgroundColorClass)
  const avatarImagePath = computed(() => levelConfig.value.avatarImagePath)
  const avatarBorderClass = computed(() => levelConfig.value.avatarBorderClass)
  const linearGradientClass = computed(() => levelConfig.value.linearGradientClass)
  const levelThresholds = computed(() => levelConfig.value.levelThresholds)

  return {
    level,
    levelConfig,
    levelName,
    lowerCaseLevelName,
    levelIcon,
    levelFlatIcon,
    levelFlatIconLightMode,
    levelImage,
    funmilesImages,
    funCoinsImages,
    levelColorClass,
    levelBackgroundColorClass,
    commenterAvatarImagePath,
    avatarBackgroundColorClass,
    avatarImagePath,
    avatarBorderClass,
    linearGradientClass,
    levelThresholds,
  }
}
